<template>
  <div class="content">
  <div class="graph" id="main" v-if="hasData"></div>
  <div id="menu" v-show="show">
    <div class="menu" @click="goTo">编辑标签</div>
    <div class="menu" @click="searchGraph">查看标签关系</div>
  </div>
</div>
</template>

<script>
import echarts from '@/components/echarts'

export default {
  name: 'TagGraph',
  components:{
  },
  props:{
    root:{
      type:Number,
      require:true
    },
    labelRelationship:{
      type: Object,
      require: true
    }
  },
  data() { 
    return {
      data: [],
      links: [],
      i:10,
      show:false,
      selectedLabel:{
        id:null,
        name:null
      },
      hasData:false
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    this.loadChart()
  },
  methods:{
    loadData() {
      this.data = []
      this.links = []
      if(this.labelRelationship.length === 0) {
          this.hasData = false 
          return
        }
      this.hasData = true
      this.labelRelationship.labels.forEach((item) => {
          this.data.push({
            name:item.id + '',
            desc: item.name,
            category: item.id === this.root? 0 : 1,
            symbolSize: 60,
          })
      })
      this.labelRelationship.labelRelations.forEach((item) => {
        this.links.push({
          source: item.labelId + '',
          target: item.childLabelId + '',
          name:item.id
        })
      })
    },
    loadChart() {
      var _this = this
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;
      var categories = [
            {
              name: '类目0'
            },
            {
              name: '类目1'
            }
          ];

      option = {
        series: [
          {
            name: 'Les Miserables',
            type: 'graph',
            layout: 'force',
            edgeSymbol: ['none', 'arrow'],
            data: this.data,
            links: this.links,
            categories: categories,
            roam: true,
            label: {
              show:true,
              position: 'inside',
              formatter: (params) => {
                return params.data.desc
              }
            },
            lineStyle: {
              color: 'source',
              // curveness: 0.3
            },
            itemStyle:{
              
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 10
              }
            },
            force: {
              repulsion: 1000,
              // edgeLength: 30, //距离
              gravity:0.5,
              edgeLength: [70, 150]
            }
          }
        ]
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
      myChart.on('click',function(params) {
        if(params.dataType === "node") {
          setTimeout(() => { 
            _this.selectedLabel.name = params.data.desc
            _this.selectedLabel.id = params.data.name
            _this.show = true
            var menu=document.querySelector("#menu");
            menu.style.left=params.event.offsetX+'px';
            menu.style.top=params.event.offsetY+'px';
          },0)
        } 
      })

      window.addEventListener('click', function () {
        _this.show = false //点击取消
      })
    },
    goTo() {
       this.$router.push({
        path: "/system/tag/list/project",
        query: {
          labelId: this.selectedLabel.id,
          labelName: this.selectedLabel.name,
        },
      });
    },
    searchGraph() {
      this.$emit('searchGraph',+this.selectedLabel.id)
    }
  },
  watch:{
    labelRelationship() {
      this.loadData()
      this.loadChart()
    }
  }
 }
</script>

<style scoped>
.content{
  position: relative;
}

.graph{
  width:80vw;
  height: 40vh;
  margin: 0 auto;
  }

#menu{
  width: 125px;
  height: 50px;
  overflow: hidden; /*隐藏溢出的元素*/
  box-shadow: 0 1px 1px #888,1px 0 1px #ccc;
  position: absolute; /*自定义菜单相对与body元素进行定位*/
  left: 0px;
  top: 0px;
  background-color: #fff;
  opacity:1 /*设置为0 隐藏自定义菜单*/
}

.menu{
  width: 130px;
  height: 25px;
  line-height: 25px;
  padding: 0 10px;
  color:black;
  background-color: #fff;
}

.menu:hover {
  background-color: #1890ff;
  color:white
}
</style>