<template>
  <div>
    <div>
      <div class="input-box">
        <a-icon type="search" class="color-icon" style="margin-right:10px; font-size:20px" ></a-icon>
        <a-select
          placeholder="请输入标签的名称搜索"
          type="primary"
          style="width:250px"
          @search="onSearch"
          @change="onChange"
          :filter-option="false"
          :showArrow="false"
          v-model="value"
          showSearch
          allowClear
        >        
            <a-select-option v-for="item in selectList" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option>
        </a-select>
      </div>
      <tag-graph @searchGraph="changeTag" :root="root" :labelRelationship="labelRelationship" v-if="!isloading"></tag-graph>
    </div>
    <div>
      <route-view></route-view>
    </div>
  </div>
</template>

<script>
import RouteView from "@/layout/RouteView";
import TagGraph from "./components/TagGraph.vue"
import debounce from 'lodash.debounce'
import { searchTag,getLabelRelationship } from "@/api/tag.js"

export default {
  name: 'Index',
  components:{
    RouteView,
    TagGraph
  },
  props:{
  },
  data() { 
    return {
      selectList:[],
      labelRelationship:{},
      value:'',
      isloading:true,
      root:null
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    loadData() {
      this.onSearch = debounce(this.onSearch, 300)
    },
    onSearch(value) {
      if(!value || value.length < 1) return
      this.selectList = []
      searchTag({name:value}).then((res) => {
        this.selectList = res.data
      })
    },
    onChange(value) {
      if(!value) {
        this.labelRelationship = {}
        this.isloading = false
        return
      }
      getLabelRelationship({labelId:value}).then((res) => {
        this.root = value
        this.labelRelationship = res.data
        this.isloading = false
      })
    },
    changeTag(value) {
      this.onChange(value);
      this.value = ''
    }
  },
 }
</script>

<style scoped>
.input-box{
  text-align: center;
  margin-bottom: 20px;
}
</style>